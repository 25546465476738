<template>
  <div class="container">
    <breadcrumb :breadcrumbList="breadcrumbLogList"></breadcrumb>
    <div class="main-content">
      <div style="display: flex;justify-content: space-between;align-items: center;margin-bottom:40px;">
        <el-form ref="ruleFormRef" label-position="left" :model="ruleForm"
        class="demo-ruleForm">
          <el-form-item prop="dateList" label="付费时间：" style="margin-right: 20px;">
              <el-date-picker @change="handleDateChange" v-model="dateList" value-format="YYYY-MM-DD" type="daterange" range-separator="到"
              start-placeholder="开始时间" end-placeholder="结束时间" />
          </el-form-item>
            <el-form-item prop="keyword" label="">
                <el-input prefix-icon="search" v-model="ruleForm.keyword" placeholder="输入姓名或手机号" clearable></el-input>
            </el-form-item>
            <div class="search-btn">
              <el-button @click="searchClick" size="" type="primary">搜索</el-button>
            </div>
        </el-form>
      </div>
      <el-table :data="tabeleData" style="width: 100%">
        <el-table-column align="center" prop="payTime" label="付费时间" />
        <el-table-column align="center" prop="specification" label="规格"/>
        <el-table-column align="center" prop="amount" label="付费金额（元）">
          <template #default="{row}">
            ¥{{row.amount}}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="nickname" label="付费人" />
        <el-table-column align="center" prop="expireDate" label="到期时间" />
        <el-table-column label="操作" align="center" width="100px" fixed="right">
          <template #default="scope">
            <el-button type="danger" size="small" @click="handleRefundClick(scope.row)">退款</el-button>
          </template>
        </el-table-column>
        
      </el-table>
      <div class="el-pagination">
        <el-pagination v-model:currentPage="currentPage.current" v-model:page-size="currentPage.size"
            :page-sizes="[10, 20, 30, 40]" layout="total, sizes, prev, pager, next, jumper" :total="total"
            @size-change="handleSizeChange" @current-change="handleCurrentChange" :pager-count="3" />
      </div>
    </div>
  </div>
  </template>
  
  <script lang="js">
  import { defineComponent, reactive, ref, onUnmounted ,watch,computed} from 'vue'
  import PageContent from '@/components/page-content2/src/page-content.vue'
  import { useRouter } from 'vue-router'
  import { popup } from '@/utils/pop-up'
  import { ElMessage } from 'element-plus'
  import {getPurchaseMonthlyMemberRecordList,returnd} from '@/service/main/member'
  import { breadcrumbLogList } from './config'
  import Breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
  export default defineComponent({
    props: {
    },
    components: {
      PageContent,
      Breadcrumb
    },
    setup(props) {
      const router = useRouter()
  
      const tabeleData = ref([])
      const ruleForm = reactive({})
      const total = ref(0)
      const currentPage = reactive({
        current: 1,
        size: 10
      })
      watch(currentPage, () => {
        getOrderList()
      }, {
        deep: true
      })
      const dateList = ref([])
      const handleDateChange = value => {
        if (value) {
          ruleForm.startDate = value[0]
          ruleForm.endDate = value[1]
        }else{
          ruleForm.startDate = undefined
          ruleForm.endDate = undefined
        }
      }

      const handleRefundClick = (item) =>{
        popup('是否确退款?', async() => {
          let res = await returnd({id:item.id})
          if (res.code==0) {
            ElMessage.success({
              message: '退款成功!',
              type: 'success'
            })
            initPage()
          }else{
            ElMessage.error({
              message: res.msg,
              type: 'error'
            })
          }
        })
      }
  
      const searchClick = () =>{
        getOrderList()
      }
      const handleSizeChange = (number) => {
        currentPage.size = number
      }
  
      const handleCurrentChange = (number) => {
        currentPage.current = number
      }
      const getOrderList = async() => {
          let data = await getPurchaseMonthlyMemberRecordList({...currentPage,...ruleForm})
  
          tabeleData.value = data.data.list
          total.value = Number(data.data.total)
      }
      const initPage = async () => {
          getOrderList()
      }
      initPage()
  
      return {
        ruleForm,
        getOrderList,
        tabeleData,
        handleCurrentChange,
        currentPage,
        handleSizeChange,
        total,
        searchClick,
        dateList,
        handleDateChange,
        handleRefundClick,
        breadcrumbLogList
      }
  
    }
  })
  </script>
  
  <style scoped lang="less">
   .container {
    min-width: 1080px;
  
    .main-content {
      padding: 15px;
      margin: 20px;
      background-color: #fff;
    }
    
    .main-content{
        min-height: 95vh;
        padding: 30px 20px;
        &:deep(.el-table) {
          th.el-table__cell{
            background-color: rgba(239, 239, 239, 1) !important;
            .cell {
              color: rgba(80, 80, 80, 1) !important;
              font-size: 14px !important;
              font-weight: 500 !important;
            }
          }
          .el-table__header-wrapper {
          
          .el-table__cell {

            background-color: rgba(239, 239, 239, 1);
            // padding: 9px 0 !important;
            // border:1px solid rgb(223, 223, 223);

            .cell {
              color: rgba(80, 80, 80, 1) !important;
              font-size: 14px !important;
              font-weight: 500 !important;
            }
          }

        }
          .cell {
            padding-left: 0 !important;
            padding-right: 0 !important;
          }
        }
        .demo-ruleForm{
          display: flex;
        }
        .el-form-item{
          margin-bottom: 0 !important;
        }
        .search-btn{
          margin-left: 20px;
        }
        .line {
          display: inline-block;
          margin: 0 6px;
        }
    }
  }

  </style>
  